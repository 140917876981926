import mutations from '../articles/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import {
  FORM_MODE,
  BASE_WORKING_TIME,
  ALLOW_OR_NOT,
  TIMEKEEPING_TYPE,
  YES_OR_NO,
} from '@/util/common-constant';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { trimValueObj } from '@/util/common-utils';
import { showConfirm } from '@/util/confirm';
import dayjs from 'dayjs';
import _ from 'lodash';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  articles: {},
  api: ConstantAPI.TIME,
  visible: false,
  selectedItem: null,
  formMode: FORM_MODE.VIEW,
  formState: {
    active: true,
    breakTime: 0,
    finishBreakTime: null,
    finishTime: null,
    id: 0,
    startBreakTime: null,
    startTime: null,
    workShiftName: '',
    workUnits: 0,
    timeWork: 0,
  },
});

const actions = {
  preCreate({ state }) {
    state.formMode = FORM_MODE.CREATE;
    state.formState = {
      active: true,
      breakTime: 0,
      finishBreakTime: null,
      finishTime: null,
      id: 0,
      startBreakTime: null,
      startTime: null,
      workShiftName: '',
      isAllowLated: ALLOW_OR_NOT.NOT_ALLOW,
      AllowLated: 10,
      isAllowExitEarly: ALLOW_OR_NOT.NOT_ALLOW,
      AllowExitEarly: 10,
      checkInEarly: TIMEKEEPING_TYPE.BY_TIME,
      AllowCheckInEarly: 10,
      checkOutLate: TIMEKEEPING_TYPE.BY_TIME,
      AllowCheckOutLate: 10,
      listEmployee: [],
      applyToEmployee: YES_OR_NO.NO,
    };
  },
  resetForm({ state }) {
    state.formState = {
      active: true,
      breakTime: 0,
      finishBreakTime: null,
      finishTime: null,
      id: 0,
      startBreakTime: null,
      startTime: null,
      workShiftName: '',
      isAllowLated: ALLOW_OR_NOT.NOT_ALLOW,
      AllowLated: 10,
      isAllowExitEarly: ALLOW_OR_NOT.NOT_ALLOW,
      AllowExitEarly: 10,
      checkInEarly: TIMEKEEPING_TYPE.BY_TIME,
      AllowCheckInEarly: 10,
      checkOutLate: TIMEKEEPING_TYPE.BY_TIME,
      AllowCheckOutLate: 10,
      listEmployee: [],
      applyToEmployee: YES_OR_NO.NO,
    };
  },
  changeAction({ state }, formMode) {
    state.formMode = formMode;
  },
  async getDetail({ state }, id) {
    try {
      const response = await DataService.callApi(state.api.GET_DETAIL, null, {
        workShiftId: id,
      });
      let success;
      checkResponse(
        response,
        () => {
          success = true;
          state.formState = response.data;
          state.formState.finishTime = state.formState.finishTime
            ? dayjs(state.formState.finishTime, 'YYYY-MM-DD HH:mm:ss')
            : null;
          state.formState.startTime = state.formState.startTime
            ? dayjs(state.formState.startTime, 'YYYY-MM-DD HH:mm:ss')
            : null;
          state.formState.startBreakTime = state.formState.startBreakTime
            ? dayjs(state.formState.startBreakTime, 'YYYY-MM-DD HH:mm:ss')
            : null;
          state.formState.finishBreakTime = state.formState.finishBreakTime
            ? dayjs(state.formState.finishBreakTime, 'YYYY-MM-DD HH:mm:ss')
            : null;
          state.formState.isAllowLated = state.formState.AllowLated
            ? ALLOW_OR_NOT.ALLOW
            : ALLOW_OR_NOT.NOT_ALLOW;
          state.formState.isAllowExitEarly = state.formState.AllowExitEarly
            ? ALLOW_OR_NOT.ALLOW
            : ALLOW_OR_NOT.NOT_ALLOW;
          state.formState.checkInEarly = state.formState.AllowCheckInEarly
            ? TIMEKEEPING_TYPE.BEFORE_TIME
            : TIMEKEEPING_TYPE.BY_TIME;
          state.formState.checkOutLate = state.formState.AllowCheckOutLate
            ? TIMEKEEPING_TYPE.BEFORE_TIME
            : TIMEKEEPING_TYPE.BY_TIME;
        },
        () => {
          success = false;
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async create({ state }, data) {
    const { t } = i18n.global;
    try {
      let payload = _.cloneDeep(data);
      if (payload.isAllowLated === ALLOW_OR_NOT.NOT_ALLOW) {
        delete payload.AllowLated;
      }
      if (payload.isAllowExitEarly === ALLOW_OR_NOT.NOT_ALLOW) {
        delete payload.AllowExitEarly;
      }
      if (payload.checkInEarly === TIMEKEEPING_TYPE.BY_TIME) {
        delete payload.AllowCheckInEarly;
      }
      if (payload.checkOutLate === TIMEKEEPING_TYPE.BY_TIME) {
        delete payload.AllowCheckOutLate;
      }
      delete payload.isAllowLated;
      delete payload.isAllowExitEarly;
      delete payload.checkInEarly;
      delete payload.checkOutLate;
      trimValueObj(payload);
      const response = await DataService.callApi(state.api.CREATE, payload);
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('time.create-time-success'),
          duration: 4,
        });
        state.formMode = FORM_MODE.VIEW;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, params) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('time.confirm-delete-time'))) {
        const response = await DataService.callApi(state.api.DELETE, {
          workShiftId: params.workShiftId,
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('time.delete-success'),
            duration: 4,
          });
          state.formMode = FORM_MODE.VIEW;
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async update({ state }, data) {
    const { t } = i18n.global;
    try {
      let payload = _.cloneDeep(data);
      if (payload.baseWorkTime === BASE_WORKING_TIME.BY_HOUR) {
        delete payload.baseWorkTimeByDay;
      } else delete payload.baseWorkTimeByHour;
      if (payload.isAllowLated === ALLOW_OR_NOT.NOT_ALLOW) {
        delete payload.AllowLated;
      }
      if (payload.isAllowExitEarly === ALLOW_OR_NOT.NOT_ALLOW) {
        delete payload.AllowExitEarly;
      }
      if (payload.checkInEarly === TIMEKEEPING_TYPE.BY_TIME) {
        delete payload.AllowCheckInEarly;
      }
      if (payload.checkOutLate === TIMEKEEPING_TYPE.BY_TIME) {
        delete payload.AllowCheckOutLate;
      }
      delete payload.isAllowLated;
      delete payload.isAllowExitEarly;
      delete payload.checkInEarly;
      delete payload.checkOutLate;
      trimValueObj(payload);
      const response = await DataService.callApi(state.api.UPDATE, payload);
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('time.update-success'),
          duration: 4,
        });
        state.formMode = FORM_MODE.VIEW;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
